export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'PieChartIcon',
    action: 'read',
    resource: 'Ver usuarios',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Ver usuarios',
  },
  {
    title: 'Roles y Permisos',
    route: 'roles',
    icon: 'LockIcon',
    action: 'read',
    resource: 'Ver roles',
  },
  {
    title: 'Auditoría',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Analista',
    children: [
      {
        title: 'Asignación',
        route: 'auditory',
        action: 'read',
        resource: 'Analista',
      }
    ],
  },
  {
    title: 'Recobros',
    icon: 'FileTextIcon',
    action: 'read',
    route: 'recobros',
    resource: 'Recobros'
  },
  {
    title: 'Radicados',
    icon: 'FileTextIcon',
    action: 'read',
    route: 'radicados',
    resource: 'Radicados' 
  },
  {
    title: 'Coordinación',
    icon: 'BriefcaseIcon',
    action: 'read',
    resource: 'Lista Coordinador',
    children: [
      {
        title: 'Asignación',
        route: 'assignment-coordination',
        action: 'read',
        resource: 'Lista Coordinador',
      },
      {
        title: 'Glosa masiva',
        route: 'massive-calification',
        action: 'read',
        resource: 'Lista Coordinador',
      },
    ],
  },
  /*{
    title: 'Calidad',
    icon: 'CheckCircleIcon',
    action: 'read',
    resource: 'Lista Coordinador',
    children: [
      {
        title: 'Asignación',
        route: 'productivity-batches',
        action: 'read',
        resource: 'Calidad',
      }
    ],
  },*/
]
